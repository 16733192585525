<template>
  <section
    class="w-full flex flex-col gap-10 items-start py-4 px-5 border-b border-lightFrost md:py-8 md:flex-row md:justify-between"
  >
    <div class="max-w-[538px] w-full space-y-3">
      <h2 class="text-base font-bold text-DarkJungleGreen">
        {{ section.title }}
      </h2>
      <p class="text-sm text-Cinder font-normal">{{ section.description }}</p>
    </div>

    <div class="max-w-[500px] w-full space-y-4">
      <div
        v-for="(item, index) in section.items"
        :key="index"
        class="w-full flex items-start gap-4"
      >
        <ToggleSwitch
          :modelValue="item.enabled"
          :disabled="disabled"
          @update:modelValue="(value) => emitToggle(index, value)"
        />

        <div class="max-w-[435px] w-full flex flex-col items-start gap-1">
          <h4 class="text-sbase font-bold text-DarkJungleGreen">
            {{ item.name }}
          </h4>
          <p class="text-sm font-normal text-Cinder">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { watch } from "vue";
import ToggleSwitch from "@/components/main/ui/ToggleSwitch.vue";

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["toggle"]);

const emitToggle = (index, value) => {
  if (!props.disabled) {
    emit("toggle", { index, value });
  }
};
</script>

<style scoped></style>
