import axios from "@/api";

export const noDP =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const state = {
  user: null,
  patientId: null,
  bloodGlucoseRecords:
    JSON.parse(localStorage.getItem("bloodGlucoseRecords")) || null,
  patientsList: null,
  patientProfile: null,
  profilePicture: null,
  userUpdatePersonalInfo: null,
  userUpdateMedicalInfo: null,
  patientProgressScore: null,
  allDocumentUploads: [],
  profilePercentage: null,
  totalResults: 0,
  perPage: 10,
  totalPages: 1,
};

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getPatientId: (state) => {
    return state.patientId;
  },
  getUserProfilePicture: (state) => {
    return state.profilePicture;
  },
  getUserBloodGlucoseRecords: (state) => {
    return state.bloodGlucoseRecords;
  },
  getPatientsList: (state) => {
    return state.patientsList;
  },
  getPatientProfile: (state) => {
    return state.patientProfile;
  },
  getUserUpdatePersonalInfo: (state) => {
    return state.userUpdatePersonalInfo;
  },
  getUserUpdateMedicalInfo: (state) => {
    return state.userUpdateMedicalInfo;
  },
  getPatientProgressScore: (state) => {
    return state.patientProgressScore;
  },
  getAllDocumentUploads: (state) => {
    return state.allDocumentUploads;
  },
  getProfilePercentage: (state) => {
    return state.profilePercentage;
  },
  totalPages: (state) => state.totalPages,
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setPatientId(state, payload) {
    state.patientId = payload;
  },
  // setBloodGlucoseRecords(state, payload) {
  //   state.bloodGlucoseRecords = payload;
  //   localStorage.setItem("bloodGlucoseRecords", JSON.stringify(payload));
  // },

  setPatientsList(state, payload) {
    state.patientsList = payload;
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  setPatientProfile(state, payload) {
    state.patientProfile = payload;
  },
  setProfilePicture(state, payload) {
    state.profilePicture = payload;
  },

  setUserUpdatePersonalInfo(state, payload) {
    state.userUpdatePersonalInfo = payload;
  },

  setUserUpdateMedicalInfo(state, payload) {
    state.userUpdateMedicalInfo = payload;
  },

  setPatientProgressScore(state, payload) {
    state.patientProgressScore = payload;
  },
  setAllDocumentUploads(state, payload) {
    state.allDocumentUploads = payload;
  },
  setProfilePercentage(state, payload) {
    state.profilePercentage = payload;
  },
  setTotalResults(state, totalResults) {
    state.totalResults = totalResults;
  },
  setTotalPages(state, totalPages) {
    state.totalPages = totalPages;
  },
};

const actions = {
  // fetch patient glucose level
  async fetchBloodGlucoseRecords({ commit }, params = {}) {
    // Initialize an empty object for query parameters
    const queryParams = {};

    // Only add the parameter to the query if it exists in the params object
    if (params.test_type) queryParams.test_type = params.test_type;
    if (params.time_period) queryParams.time_period = params.time_period;
    if (params.page) queryParams.page = params.page;

    // Create a query string from the params object
    const queryString = new URLSearchParams(queryParams).toString();


    const response = await axios.get(
      `/data/patient/tests/list?${queryString}`
    );

    return response.data;
  },

  async exportPDF({ commit }, payload) {
    try {
      const { patient_id, date_range } = payload;
      const response = await axios.get(`/data/patient/download/${patient_id}`, {
        params: {
          start_date: date_range.start_date,
          end_date: date_range.end_date
        },
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `patient_report_${date_range.start_date}_to_${date_range.end_date}.pdf`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      throw error;
    }
  },

  // fetch list of all patients
  async fetchPatientsList({ commit, rootState }, payload) {
    const isAdmin = rootState.auth.isAdmin;
    if (isAdmin) {
      const response = await axios.get(
        `/data/patients${payload ? `?page=${payload}` : ""}`
      );
      commit("setPatientsList", response.data);

      return response.data;
    }
  },

  // fetch patient profile
  async fetchPatientProfile({ commit, state, rootState }) {
    const isAdmin = rootState.auth.isAdmin;

    if (!isAdmin) {
      const response = await axios.get(`/data/patient/${state.patientId}`);

      // Commit the fetched profile data to the state
      commit("setPatientProfile", response.data);
      return response.data;
    }
  },

  // update user personal info
  async updateUserPersonalInfo({ commit }, userUpdateCredentials) {

    const response = await axios.put(
      "/auth/user/update/profile-update",
      userUpdateCredentials
    );
    commit("setUserUpdatePersonalInfo", response.data);
    return response.data;

  },

  async setProfilePictureAction(_, payload) {

    const response = await axios.post(
      `/pictures/thumbnails/persons/${payload.id}`,
      payload.formData
    );
    return response.data;

  },

  // update user medical info
  async updateUserMedicalInfo({ commit }, userUpdateCredentials) {

    const response = await axios.put(
      "/auth/user/update/medical-info",
      userUpdateCredentials
    );
    commit("setUserUpdateMedicalInfo", response.data);
    return response.data;

  },

  async getPatientById(_, payload) {
    try {
      const response = await axios.get(`/data/patient/${payload}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getAllDocumentUploads({ commit }, { page = 1 } = {}) {
    const response = await axios.get("/data/documents", {
      params: {
        page,
        per_page: state.perPage,
      },
    });

    return response.data;

  },

  async uploadDocument(_, payload) {
    try {
      const response = await axios.post("data/upload/report", payload, {
        headers: {
          accept: "application/json",
          "Content-Type": `multipart/form-data;`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteDocument(_, payload) {
    try {
      const response = await axios.delete(`data/document/${payload}`);
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },

  async dowloadDocumentReport(_, payload) {
    try {
      const response = await axios.get(
        `data/document/patient/download/${payload.patientId}`,
        {
          date_range: {
            start_date: payload.fromDate,
            end_date: payload.toDate,
          },
        }
      );
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },

  async fetchPatientProgressScore({ commit }) {
    try {
      const response = await axios.get(`data/patient/progress_report`);
      commit("setPatientProgressScore", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  // send progress report
  async sendProgressReport(_, patientId) {

    const response = await axios.post(`/data/patient/glucose/progress/${patientId}`);
    return response.data;

  },

  // user change password
  async changePassword(_, payload) {
    try {
      await axios.post(`/auth/change-password`, {
        old_password: payload.old_password,
        password: payload.password,
        password_2: payload.password_2,
      });
    } catch (error) {
      throw error;
    }
  },

  // search patient
  async searchPatient(_, payload) {
    const username = payload.username || null;
    try {
      const response = await axios.get(
        // `/data/search?keyword=${username}&page=1`
        `/data/search?keyword=${username}&page=1`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async searchPatientAdmin(_, payload) {
    try {
      const response = await axios.post("/patient/search", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Metrics and untits
  async changeSIUnit(_, payload) {
    try {
      await axios.put("/auth/user/update/medical-info/si-unit", payload);
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
