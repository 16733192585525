<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="bg-CatskillWhite">
        <div class="container mx-auto py-2 md:py-4 space-y-3 md:space-y-6">
          <h1 class="font-bold text-ResolutionBlue text-2xl">
            Blog Post Input
          </h1>
          <form @submit.prevent="addBlogPostFc" class="space-y-3 md:space-y-6">
            <div class="bg-white rounded-lg md:px-10 py-2">
              <div class="space-y-3 md:space-y-4 mb-2 md:mb-4">
                <h4
                  class="font-medium text-ResolutionBlue text-xl md:text-[28px]"
                >
                  Author's name
                </h4>
                <textarea
                  class="font-extralight w-full border border-BattleshipGrey rounded-lg px-2 md:px-7 py-2 md:py-5 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
                  rows="1"
                  type="text"
                  name=""
                  id=""
                  placeholder="Enter author's name"
                  v-model="author"
                ></textarea>
                <div
                  class="text-ArtyClickRed"
                  v-if="addBlogError === 'author cannot be empty.'"
                >
                  Please provide author's name
                </div>
              </div>
              <p class="text-DavyGrey text-sm md:text-xl">
                We recommend that you provide your full name - your first name
                and surname for easy and accurate identification.
              </p>
            </div>
            <div class="bg-white rounded-lg md:px-10 py-2">
              <div class="space-y-3 md:space-y-4 mb-2 md:mb-4">
                <h4
                  class="font-medium text-ResolutionBlue text-xl md:text-[28px]"
                >
                  Title of the blog
                </h4>
                <textarea
                  class="font-extralight w-full border border-BattleshipGrey rounded-lg px-2 md:px-7 py-2 md:py-5 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
                  rows="3"
                  type="text"
                  name=""
                  id=""
                  placeholder="Enter blog post title"
                  v-model="title"
                ></textarea>
              </div>
              <p class="text-DavyGrey text-sm md:text-xl">
                We recommend that you keep it concise, short, and easy to
                understand. Your readers will appreciate it.
              </p>
            </div>
            <div class="bg-white rounded-lg md:px-10 py-2">
              <div class="space-y-3 md:space-y-4 mb-2 md:mb-4">
                <h4
                  class="font-medium text-ResolutionBlue text-xl md:text-[28px]"
                >
                  Main Body
                </h4>
                <Editor
                  api-key="kwrhvzmshf2xk3n0i6qyd5u6bnf0lymawjhre6dj74bxir3s"
                  v-model="content"
                  :init="{
                    plugins: 'lists link image table code help wordcount',
                  }"
                />
                <!-- <textarea
                      class="font-extralight w-full border border-BattleshipGrey rounded-lg px-2 md:px-7 py-2 md:py-5 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
                      rows="5"
                      type="text"
                      name=""
                      id=""
                      placeholder="Type here..."
                      v-model="body"
                    ></textarea> -->
              </div>
            </div>
            <div class="bg-white rounded-lg md:px-10 py-2">
              <div class="space-y-3 md:space-y-4 mb-2 md:mb-4">
                <h4
                  class="font-medium text-ResolutionBlue text-xl md:text-[28px]"
                >
                  Upload Post Image
                </h4>
                <p class="text-DavyGrey text-sm md:text-xl">
                  We recommend that you provide a square image, at least 300px
                  by 300px PNG or JPG file.
                </p>
                <input
                  name=""
                  @change="handleImageChange"
                  id="image"
                  type="file"
                  accept="image/*"
                  ref="image"
                />
              </div>
            </div>
            <div class="md:px-10">
              <button
                type="submit"
                class="bg-ResolutionBlue text-white text-center md:px-20 py-3 rounded-3xl border-white hover:bg-DodgerBlue hover:text-white hover:border-DodgerBlue transition-all duration-500 w-32 flex justify-center items-center"
              >
                <span v-if="isPending">
                  <LoadingSpinner />
                </span>
                <span v-else>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Editor from "@tinymce/tinymce-vue";

const queryClient = useQueryClient();

const { "blog/addBlogPost": addBlogPost } = mapActions();

const author = ref("");
const title = ref("");
const content = ref("");
const image = ref(null);
const addBlogError = ref(null);

const handleImageChange = (event) => {
  const file = event.target.files[0];
  image.value = file;
};

const { isPending, mutate } = useMutation({
  mutationFn: (data) => addBlogPost(data),
  onError: (error) => {
    if (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        addBlogError.value = error.response.data.message;
        push.error(addBlogError.value);
      } else {
        push.error("Error adding blog post");
      }
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["blog-posts"] });
    // if (blogPost.message === "Blog post created successfully") {
    // }
    push.success("Post added successfully");
    author.value = "";
    title.value = "";
    body.value = "";
    image.value = "";
  },
});

const addBlogPostFc = () => {
  const formData = new FormData();
  formData.append("author", author.value);
  formData.append("title", title.value);
  formData.append("body", content.value);
  if (image.value) {
    formData.append("image", image.value);
  }

  mutate(formData);
};
</script>
