<template>
  <button
    @click="toggle"
    :disabled="disabled"
    :class="[
      'relative w-12 h-6 rounded-full transition-colors duration-200',
      modelValue ? 'bg-ResolutionBlue' : 'bg-mistGray',
      disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
    ]"
  >
    <span
      :class="[
        'absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow-xl transition-transform duration-200',
        modelValue ? 'translate-x-6' : 'translate-x-0',
        disabled ? 'opacity-50' : '',
      ]"
    ></span>
  </button>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const toggle = () => {
  if (!props.disabled) {
    emit("update:modelValue", !props.modelValue);
  }
};
</script>

<style scoped></style>
