<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="w-full flex flex-row items-center justify-between mb-5">
        <div class="flex items-center space-x-16">
          <p class="text-ResolutionBlue font-bold text-xl lg:text-2xl">
            Account Information
          </p>
          <div
            class="flex flex-row items-center rounded-sm bg-WhiteLilac p-1.5"
          >
            <span
              @click="toggleTabs(1)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-10 py-1.5': openTab !== 1,
                'font-bold rounded-sm px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 1,
              }"
            >
              Personal</span
            >
            <span
              @click="toggleTabs(2)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-10 py-1.5': openTab !== 2,
                'font-bold rounded-sm px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 2,
              }"
            >
              Medical</span
            >
          </div>
        </div>
      </div>
      <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
        <PatientPersonalInfo :patient="patient" />
      </div>
      <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
        <PatientMedicalInfo :patient="patient" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { mapGetters } from "@/hooks/mapStore";
import PatientMedicalInfo from "@/components/main/admin/settings/PatientMedicalInfo.vue";
import PatientPersonalInfo from "@/components/main/admin/settings/PatientPersonalInfo.vue";

const openTab = ref(1);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};

const route = useRoute();
const { "user/getPatientsList": getPatientsList } = mapGetters();

// access the patient name from url
const patientFullName = computed(() => {
  return route.params.full_name;
});

// get patient detail
const patient = computed(() => {
  const patientsList = getPatientsList?.value.patients;
  const foundPatient = patientsList.find(
    (patient) => patient.person.full_name === patientFullName.value
  );
  return foundPatient || {};
});

onMounted(() => {
});
</script>
