<template>
  <div class="flex items-center gap-x-3 flex-wrap">
    <div
      class="flex items-center gap-x-3 text-OlsoGrey text-sm md:text-base font-bold"
    >
      <router-link
        v-if="routes[0] !== 'Settings'"
        :to="isAdmin ? '/admin/dashboard' : '/patient/dashboard'"
      >
        <img src="../../../assets/icons/home.svg" alt="home icon" />
      </router-link>

      <router-link
        v-else
        :to="isAdmin ? '/admin/settings' : '/patient/settings'"
      >
        <img src="../../../assets/icons/settings.svg" alt="settings icon" />
      </router-link>

      

      <div
        v-for="(item, index) in routes"
        :key="index"
        class="flex items-center gap-x-3"
      >
        <DirectionalRightIcon v-if="index > 0" width="8" height="12" />

        <router-link
          v-if="index === 0"
          :to="`/${isAdmin ? 'admin' : 'patient'}/${item
            .toLowerCase()
            .replace(' ', '-')}`"
          class="cursor-pointer"
          :class="
            index === routes.length - 1 && 'text-ResolutionBlue capitalize'
          "
        >
          {{ item }}
        </router-link>

        <span
          v-else
          class="cursor-pointer"
          :class="
            index === routes.length - 1 && 'text-ResolutionBlue capitalize'
          "
        >
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { mapGetters } from "@/hooks/mapStore";
import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";

const route = useRoute();

const routes = ref([]);

const { "auth/getIsAdmin": isAdmin } = mapGetters();

onMounted(() => {
  if (!route.meta?.parent) {
    routes.value = [];
    routes.value.push(route.meta.subHeaderName);
  } else if (route.meta?.parent) {
    routes.value = [];
    for (let item of route.meta.subHeaderName) {
      routes.value.push(item);
    }
  }
});

watch(route, () => {
  if (!route.meta?.parent) {
    routes.value = [];
    routes.value.push(route.meta.subHeaderName);
  } else if (route.meta?.parent) {
    routes.value = [];
    for (let item of route.meta.subHeaderName) {
      routes.value.push(item);
    }
  }
});
</script>
