<template>
  <aside
    class="fixed lg:sticky top-0 left-0 z-[70] h-screen bg-white text-white transition-all duration-300"
    :class="
      isSidebarOpen
        ? 'w-20 lg:w-[100px] translate-x-0'
        : 'lg:w-[100px] -translate-x-full lg:translate-x-0 overflow-hidden'
    "
  >
    <div
      class="flex flex-col items-center justify-center text-center transition-opacity duration-500"
      :class="isSidebarOpen ? 'opacity-100' : 'opacity-0 lg:opacity-100'"
    >
      <div class="h-[130px] flex items-center">
        <router-link to="">
          <img src="../../../assets/icons/TorbomedPatientIcon.svg" alt="" />
        </router-link>
      </div>
      <div>
        <!-- DASHBOARD -->
        <div v-show="isPatientSettings">
          <div class="w-20 lg:w-[100px]">
            <div class="flex items-center justify-between" v-if="showWhiteIcon">
              <span></span>
              <router-link
                to="/patient/dashboard"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.59018 13.8489V11.8104C6.59018 11.2901 7.01511 10.8682 7.53928 10.8682H9.4554C9.70712 10.8682 9.94853 10.9675 10.1265 11.1442C10.3045 11.3209 10.4045 11.5605 10.4045 11.8104V13.8489C10.4029 14.0652 10.4884 14.2733 10.6419 14.4268C10.7954 14.5803 11.0044 14.6667 11.2223 14.6667H12.5295C13.1401 14.6682 13.7262 14.4286 14.1584 14.0005C14.5907 13.5725 14.8337 12.9913 14.8337 12.3852V6.57792C14.8336 6.08832 14.615 5.62391 14.2367 5.30979L9.78967 1.78392C9.0161 1.16572 7.90773 1.18568 7.15725 1.83133L2.81167 5.30979C2.41549 5.61465 2.17869 6.08043 2.16699 6.57792V12.3793C2.16699 13.6426 3.19858 14.6667 4.47111 14.6667H5.74852C6.20114 14.6667 6.56899 14.3042 6.57227 13.8548L6.59018 13.8489Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/dashboard" class="p-4 rounded-lg">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.59018 13.8489V11.8104C6.59018 11.2901 7.01511 10.8682 7.53928 10.8682H9.4554C9.70712 10.8682 9.94853 10.9675 10.1265 11.1442C10.3045 11.3209 10.4045 11.5605 10.4045 11.8104V13.8489C10.4029 14.0652 10.4884 14.2733 10.6419 14.4268C10.7954 14.5803 11.0044 14.6667 11.2223 14.6667H12.5295C13.1401 14.6682 13.7262 14.4286 14.1584 14.0005C14.5907 13.5725 14.8337 12.9913 14.8337 12.3852V6.57792C14.8336 6.08832 14.615 5.62391 14.2367 5.30979L9.78967 1.78392C9.0161 1.16572 7.90773 1.18568 7.15725 1.83133L2.81167 5.30979C2.41549 5.61465 2.17869 6.08043 2.16699 6.57792V12.3793C2.16699 13.6426 3.19858 14.6667 4.47111 14.6667H5.74852C6.20114 14.6667 6.56899 14.3042 6.57227 13.8548L6.59018 13.8489Z"
                    fill="#808D9E"
                  />
                </svg>
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showNotificationWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/notifications"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <NotificationIconFilled
                  :notificationCount="notificationCount"
                />
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/notifications" class="p-4 rounded-lg">
                <NotificationIcon :notificationCount="notificationCount" />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showAccountLinkingWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/settings/account-linking"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <AccountLinkingIconFilled />
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link
                to="/patient/settings/account-linking"
                class="p-4 rounded-lg"
              >
                <AccountLinkingIcon />
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showCalendarWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/appointment"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.0625 6.7698H13.9451"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.962 9.37331H10.9681"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.00298 9.37331H8.00915"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.03813 9.37331H5.04431"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.962 11.9641H10.9681"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.00298 11.9641H8.00915"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.03813 11.9641H5.04431"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6955 1.8335V4.02735"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.31075 1.8335V4.02735"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8255 2.88623H5.18064C3.22285 2.88623 2 3.97685 2 5.98158V12.0147C2 14.0509 3.22285 15.1668 5.18064 15.1668H10.8193C12.7833 15.1668 14 14.0698 14 12.0651V5.98158C14.0062 3.97685 12.7895 2.88623 10.8255 2.88623Z"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/appointment" class="p-4 rounded-lg">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.0625 6.7698H13.9451"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.962 9.37331H10.9681"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.00298 9.37331H8.00915"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.03813 9.37331H5.04431"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.962 11.9641H10.9681"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.00298 11.9641H8.00915"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.03813 11.9641H5.04431"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6955 1.8335V4.02735"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.31075 1.8335V4.02735"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8255 2.88623H5.18064C3.22285 2.88623 2 3.97685 2 5.98158V12.0147C2 14.0509 3.22285 15.1668 5.18064 15.1668H10.8193C12.7833 15.1668 14 14.0698 14 12.0651V5.98158C14.0062 3.97685 12.7895 2.88623 10.8255 2.88623Z"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
          </div>

          <div class="w-20 lg:w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showSettingWhiteIcon"
            >
              <span></span>
              <router-link
                to="/patient/settings"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6014 8.0535C12.8399 8.18016 13.0238 8.38016 13.1533 8.58016C13.4053 8.9935 13.3849 9.50016 13.1396 9.94683L12.6627 10.7468C12.4106 11.1735 11.9405 11.4402 11.4568 11.4402C11.2184 11.4402 10.9526 11.3735 10.7346 11.2402C10.5575 11.1268 10.3531 11.0868 10.1351 11.0868C9.46058 11.0868 8.8951 11.6402 8.87466 12.3002C8.87466 13.0668 8.24786 13.6668 7.46436 13.6668H6.53779C5.74747 13.6668 5.12067 13.0668 5.12067 12.3002C5.10705 11.6402 4.54156 11.0868 3.86707 11.0868C3.64224 11.0868 3.43785 11.1268 3.26752 11.2402C3.04951 11.3735 2.77698 11.4402 2.54534 11.4402C2.0548 11.4402 1.5847 11.1735 1.33262 10.7468L0.862517 9.94683C0.610434 9.5135 0.596808 8.9935 0.848891 8.58016C0.9579 8.38016 1.16229 8.18016 1.39393 8.0535C1.5847 7.96016 1.70734 7.80683 1.82316 7.62683C2.16381 7.0535 1.95942 6.30016 1.38031 5.96016C0.705817 5.58016 0.487799 4.7335 0.876143 4.0735L1.33262 3.28683C1.72777 2.62683 2.57259 2.3935 3.2539 2.78016C3.84663 3.10016 4.61651 2.88683 4.96397 2.32016C5.07298 2.1335 5.1343 1.9335 5.12067 1.7335C5.10705 1.4735 5.18199 1.22683 5.31144 1.02683C5.56352 0.613496 6.02 0.346829 6.51735 0.333496H7.47799C7.98215 0.333496 8.43863 0.613496 8.69071 1.02683C8.81334 1.22683 8.8951 1.4735 8.87466 1.7335C8.86104 1.9335 8.92235 2.1335 9.03136 2.32016C9.37883 2.88683 10.1487 3.10016 10.7483 2.78016C11.4227 2.3935 12.2744 2.62683 12.6627 3.28683L13.1192 4.0735C13.5143 4.7335 13.2963 5.58016 12.615 5.96016C12.0359 6.30016 11.8315 7.0535 12.179 7.62683C12.288 7.80683 12.4106 7.96016 12.6014 8.0535ZM5.07298 7.00683C5.07298 8.0535 5.93824 8.88683 7.00789 8.88683C8.07754 8.88683 8.92235 8.0535 8.92235 7.00683C8.92235 5.96016 8.07754 5.12016 7.00789 5.12016C5.93824 5.12016 5.07298 5.96016 5.07298 7.00683Z"
                    fill="white"
                  />
                </svg>
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/patient/settings" class="p-4 rounded-lg">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5041 4.26479L12.1151 3.58973C11.786 3.01853 11.0567 2.82148 10.4847 3.14923V3.14923C10.2124 3.30962 9.88752 3.35513 9.58166 3.27571C9.2758 3.19629 9.01409 2.99848 8.85424 2.72589C8.75141 2.55262 8.69615 2.35528 8.69406 2.15381V2.15381C8.70334 1.83079 8.58148 1.51778 8.35623 1.28607C8.13099 1.05437 7.82155 0.923695 7.49841 0.923828H6.71466C6.39807 0.923825 6.09454 1.04998 5.87122 1.27437C5.6479 1.49877 5.5232 1.8029 5.52473 2.11948V2.11948C5.51534 2.77311 4.98277 3.29804 4.32907 3.29797C4.1276 3.29588 3.93025 3.24062 3.75699 3.13779V3.13779C3.18501 2.81004 2.45566 3.00709 2.12656 3.57829L1.70894 4.26479C1.38023 4.83528 1.57459 5.56416 2.14372 5.89522V5.89522C2.51366 6.10881 2.74155 6.50352 2.74155 6.93069C2.74155 7.35786 2.51366 7.75258 2.14372 7.96616V7.96616C1.57532 8.29499 1.38074 9.02211 1.70894 9.59088V9.59088L2.10367 10.2717C2.25787 10.5499 2.5166 10.7552 2.82259 10.8422C3.12858 10.9291 3.45662 10.8906 3.73411 10.735V10.735C4.00689 10.5759 4.33196 10.5323 4.63706 10.6139C4.94215 10.6955 5.20199 10.8957 5.35882 11.1698C5.46166 11.3431 5.51691 11.5404 5.519 11.7419V11.7419C5.519 12.4022 6.05432 12.9376 6.71466 12.9376H7.49841C8.15652 12.9376 8.69091 12.4057 8.69406 11.7476V11.7476C8.69253 11.4301 8.81801 11.125 9.04257 10.9005C9.26713 10.6759 9.57214 10.5504 9.88971 10.552C10.0907 10.5574 10.2872 10.6124 10.4618 10.7122V10.7122C11.0323 11.0409 11.7612 10.8465 12.0922 10.2774V10.2774L12.5041 9.59088C12.6636 9.31722 12.7073 8.99129 12.6257 8.68526C12.5441 8.37924 12.3439 8.11838 12.0693 7.96044V7.96044C11.7948 7.8025 11.5946 7.54164 11.513 7.23562C11.4314 6.9296 11.4751 6.60367 11.6346 6.33001C11.7382 6.14899 11.8883 5.9989 12.0693 5.89522V5.89522C12.6351 5.56434 12.829 4.83971 12.5041 4.27051V4.27051V4.26479Z"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="7.10951"
                    cy="6.9308"
                    r="1.6476"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>

        <div v-show="isAdminSettings">
          <div class="w-[100px]">
            <div class="flex items-center justify-between" v-if="showWhiteIcon">
              <span></span>
              <router-link to="" class="bg-ResolutionBlue p-4 rounded-lg">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.59018 13.8489V11.8104C6.59018 11.2901 7.01511 10.8682 7.53928 10.8682H9.4554C9.70712 10.8682 9.94853 10.9675 10.1265 11.1442C10.3045 11.3209 10.4045 11.5605 10.4045 11.8104V13.8489C10.4029 14.0652 10.4884 14.2733 10.6419 14.4268C10.7954 14.5803 11.0044 14.6667 11.2223 14.6667H12.5295C13.1401 14.6682 13.7262 14.4286 14.1584 14.0005C14.5907 13.5725 14.8337 12.9913 14.8337 12.3852V6.57792C14.8336 6.08832 14.615 5.62391 14.2367 5.30979L9.78967 1.78392C9.0161 1.16572 7.90773 1.18568 7.15725 1.83133L2.81167 5.30979C2.41549 5.61465 2.17869 6.08043 2.16699 6.57792V12.3793C2.16699 13.6426 3.19858 14.6667 4.47111 14.6667H5.74852C6.20114 14.6667 6.56899 14.3042 6.57227 13.8548L6.59018 13.8489Z"
                    fill="#FFFFFF"
                  />
                </svg>
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="/admin/dashboard" class="p-4 rounded-lg">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.59018 13.8489V11.8104C6.59018 11.2901 7.01511 10.8682 7.53928 10.8682H9.4554C9.70712 10.8682 9.94853 10.9675 10.1265 11.1442C10.3045 11.3209 10.4045 11.5605 10.4045 11.8104V13.8489C10.4029 14.0652 10.4884 14.2733 10.6419 14.4268C10.7954 14.5803 11.0044 14.6667 11.2223 14.6667H12.5295C13.1401 14.6682 13.7262 14.4286 14.1584 14.0005C14.5907 13.5725 14.8337 12.9913 14.8337 12.3852V6.57792C14.8336 6.08832 14.615 5.62391 14.2367 5.30979L9.78967 1.78392C9.0161 1.16572 7.90773 1.18568 7.15725 1.83133L2.81167 5.30979C2.41549 5.61465 2.17869 6.08043 2.16699 6.57792V12.3793C2.16699 13.6426 3.19858 14.6667 4.47111 14.6667H5.74852C6.20114 14.6667 6.56899 14.3042 6.57227 13.8548L6.59018 13.8489Z"
                    fill="#808D9E"
                  />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showAdminWhiteIcon"
            >
              <span></span>
              <router-link
                to="/admin/report-and-statictics"
                class="bg-ResolutionBlue p-4 rounded-lg"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6014 8.0535C12.8399 8.18016 13.0238 8.38016 13.1533 8.58016C13.4053 8.9935 13.3849 9.50016 13.1396 9.94683L12.6627 10.7468C12.4106 11.1735 11.9405 11.4402 11.4568 11.4402C11.2184 11.4402 10.9526 11.3735 10.7346 11.2402C10.5575 11.1268 10.3531 11.0868 10.1351 11.0868C9.46058 11.0868 8.8951 11.6402 8.87466 12.3002C8.87466 13.0668 8.24786 13.6668 7.46436 13.6668H6.53779C5.74747 13.6668 5.12067 13.0668 5.12067 12.3002C5.10705 11.6402 4.54156 11.0868 3.86707 11.0868C3.64224 11.0868 3.43785 11.1268 3.26752 11.2402C3.04951 11.3735 2.77698 11.4402 2.54534 11.4402C2.0548 11.4402 1.5847 11.1735 1.33262 10.7468L0.862517 9.94683C0.610434 9.5135 0.596808 8.9935 0.848891 8.58016C0.9579 8.38016 1.16229 8.18016 1.39393 8.0535C1.5847 7.96016 1.70734 7.80683 1.82316 7.62683C2.16381 7.0535 1.95942 6.30016 1.38031 5.96016C0.705817 5.58016 0.487799 4.7335 0.876143 4.0735L1.33262 3.28683C1.72777 2.62683 2.57259 2.3935 3.2539 2.78016C3.84663 3.10016 4.61651 2.88683 4.96397 2.32016C5.07298 2.1335 5.1343 1.9335 5.12067 1.7335C5.10705 1.4735 5.18199 1.22683 5.31144 1.02683C5.56352 0.613496 6.02 0.346829 6.51735 0.333496H7.47799C7.98215 0.333496 8.43863 0.613496 8.69071 1.02683C8.81334 1.22683 8.8951 1.4735 8.87466 1.7335C8.86104 1.9335 8.92235 2.1335 9.03136 2.32016C9.37883 2.88683 10.1487 3.10016 10.7483 2.78016C11.4227 2.3935 12.2744 2.62683 12.6627 3.28683L13.1192 4.0735C13.5143 4.7335 13.2963 5.58016 12.615 5.96016C12.0359 6.30016 11.8315 7.0535 12.179 7.62683C12.288 7.80683 12.4106 7.96016 12.6014 8.0535ZM5.07298 7.00683C5.07298 8.0535 5.93824 8.88683 7.00789 8.88683C8.07754 8.88683 8.92235 8.0535 8.92235 7.00683C8.92235 5.96016 8.07754 5.12016 7.00789 5.12016C5.93824 5.12016 5.07298 5.96016 5.07298 7.00683Z"
                    fill="white"
                  />
                </svg>
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link
                to="/admin/report-and-statictics"
                class="p-4 rounded-lg"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5041 4.26479L12.1151 3.58973C11.786 3.01853 11.0567 2.82148 10.4847 3.14923V3.14923C10.2124 3.30962 9.88752 3.35513 9.58166 3.27571C9.2758 3.19629 9.01409 2.99848 8.85424 2.72589C8.75141 2.55262 8.69615 2.35528 8.69406 2.15381V2.15381C8.70334 1.83079 8.58148 1.51778 8.35623 1.28607C8.13099 1.05437 7.82155 0.923695 7.49841 0.923828H6.71466C6.39807 0.923825 6.09454 1.04998 5.87122 1.27437C5.6479 1.49877 5.5232 1.8029 5.52473 2.11948V2.11948C5.51534 2.77311 4.98277 3.29804 4.32907 3.29797C4.1276 3.29588 3.93025 3.24062 3.75699 3.13779V3.13779C3.18501 2.81004 2.45566 3.00709 2.12656 3.57829L1.70894 4.26479C1.38023 4.83528 1.57459 5.56416 2.14372 5.89522V5.89522C2.51366 6.10881 2.74155 6.50352 2.74155 6.93069C2.74155 7.35786 2.51366 7.75258 2.14372 7.96616V7.96616C1.57532 8.29499 1.38074 9.02211 1.70894 9.59088V9.59088L2.10367 10.2717C2.25787 10.5499 2.5166 10.7552 2.82259 10.8422C3.12858 10.9291 3.45662 10.8906 3.73411 10.735V10.735C4.00689 10.5759 4.33196 10.5323 4.63706 10.6139C4.94215 10.6955 5.20199 10.8957 5.35882 11.1698C5.46166 11.3431 5.51691 11.5404 5.519 11.7419V11.7419C5.519 12.4022 6.05432 12.9376 6.71466 12.9376H7.49841C8.15652 12.9376 8.69091 12.4057 8.69406 11.7476V11.7476C8.69253 11.4301 8.81801 11.125 9.04257 10.9005C9.26713 10.6759 9.57214 10.5504 9.88971 10.552C10.0907 10.5574 10.2872 10.6124 10.4618 10.7122V10.7122C11.0323 11.0409 11.7612 10.8465 12.0922 10.2774V10.2774L12.5041 9.59088C12.6636 9.31722 12.7073 8.99129 12.6257 8.68526C12.5441 8.37924 12.3439 8.11838 12.0693 7.96044V7.96044C11.7948 7.8025 11.5946 7.54164 11.513 7.23562C11.4314 6.9296 11.4751 6.60367 11.6346 6.33001C11.7382 6.14899 11.8883 5.9989 12.0693 5.89522V5.89522C12.6351 5.56434 12.829 4.83971 12.5041 4.27051V4.27051V4.26479Z"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="7.10951"
                    cy="6.9308"
                    r="1.6476"
                    stroke="#808D9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="w-[100px]">
            <div
              class="flex items-center justify-between"
              v-if="showSettingWhiteIcon"
            >
              <span></span>
              <router-link to="" class="bg-ResolutionBlue p-4 rounded-lg">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="3 User">
                    <path
                      id="Stroke 1"
                      d="M11.9258 5.26416C12.8558 5.13349 13.5718 4.33616 13.5738 3.37016C13.5738 2.41816 12.8798 1.62883 11.9698 1.47949"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 3"
                      d="M13.1523 7.5C14.053 7.63467 14.6817 7.95 14.6817 8.6C14.6817 9.04733 14.3857 9.338 13.907 9.52067"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 5"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.92481 7.77539C5.78215 7.77539 3.95215 8.10006 3.95215 9.39672C3.95215 10.6927 5.77082 11.0267 7.92481 11.0267C10.0675 11.0267 11.8968 10.7054 11.8968 9.40806C11.8968 8.11072 10.0788 7.77539 7.92481 7.77539Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 7"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.92492 5.92501C9.33092 5.92501 10.4709 4.78567 10.4709 3.37901C10.4709 1.97301 9.33092 0.833008 7.92492 0.833008C6.51892 0.833008 5.37893 1.97301 5.37893 3.37901C5.37359 4.78034 6.50493 5.92034 7.90626 5.92501H7.92492Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 9"
                      d="M3.92339 5.26416C2.99272 5.13349 2.27739 4.33616 2.27539 3.37016C2.27539 2.41816 2.96939 1.62883 3.87939 1.47949"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 11"
                      d="M2.69633 7.5C1.79566 7.63467 1.16699 7.95 1.16699 8.6C1.16699 9.04733 1.46299 9.338 1.94166 9.52067"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </router-link>
              <span>
                <img
                  src="../../../assets/icons/sidebar_border_right.svg"
                  alt=""
                />
              </span>
            </div>
            <div class="flex justify-center" v-else>
              <router-link to="" class="p-4 rounded-lg">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="3 User">
                    <path
                      id="Stroke 1"
                      d="M11.9258 5.26416C12.8558 5.13349 13.5718 4.33616 13.5738 3.37016C13.5738 2.41816 12.8798 1.62883 11.9698 1.47949"
                      stroke="#808D9E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 3"
                      d="M13.1523 7.5C14.053 7.63467 14.6817 7.95 14.6817 8.6C14.6817 9.04733 14.3857 9.338 13.907 9.52067"
                      stroke="#808D9E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 5"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.92481 7.77539C5.78215 7.77539 3.95215 8.10006 3.95215 9.39672C3.95215 10.6927 5.77082 11.0267 7.92481 11.0267C10.0675 11.0267 11.8968 10.7054 11.8968 9.40806C11.8968 8.11072 10.0788 7.77539 7.92481 7.77539Z"
                      stroke="#808D9E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 7"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.92492 5.92501C9.33092 5.92501 10.4709 4.78567 10.4709 3.37901C10.4709 1.97301 9.33092 0.833008 7.92492 0.833008C6.51892 0.833008 5.37893 1.97301 5.37893 3.37901C5.37359 4.78034 6.50493 5.92034 7.90626 5.92501H7.92492Z"
                      stroke="#808D9E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 9"
                      d="M3.92339 5.26416C2.99272 5.13349 2.27739 4.33616 2.27539 3.37016C2.27539 2.41816 2.96939 1.62883 3.87939 1.47949"
                      stroke="#808D9E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Stroke 11"
                      d="M2.69633 7.5C1.79566 7.63467 1.16699 7.95 1.16699 8.6C1.16699 9.04733 1.46299 9.338 1.94166 9.52067"
                      stroke="#808D9E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute bottom-12">
        <BaseButton @click="showLogoutModal = true">
          <img src="../../../assets/icons/logout_icon.svg" alt="logout icon" />
        </BaseButton>

        <LogoutModal :show="showLogoutModal" @close="showLogoutModal = false" />
      </div>
    </div>
  </aside>
</template>

<script setup>
import { computed, inject, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import NotificationIcon from "@/components/icons/NotificationIcon.vue";
import NotificationIconFilled from "@/components/icons/NotificationIconFilled.vue";
import AccountLinkingIcon from "@/components/icons/AccountLinkingIcon.vue";
import AccountLinkingIconFilled from "@/components/icons/AccountLinkingIconFilled.vue";
import LogoutModal from "../ui/modals/LogoutModal.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue"

    const route = useRoute();
    const store = useStore();
    
    const isSidebarOpen = inject("isSidebarOpen");
    const showLogoutModal = ref(false);
    const notificationCount = computed(() => store.getters["notifications/getNotificationCounts"].allNotifications);
    // Computed properties for icon visibility based on routes
    const showWhiteIcon = computed(() => 
      ["/patient/dashboard", "/patient/blood-glucose", "/admin/dashboard"].includes(route.path)
    );
    const showNotificationWhiteIcon = computed(() => 
      route.path.startsWith("/patient/notifications")
    );
    const showAccountLinkingWhiteIcon = computed(() => 
      route.path.startsWith("/patient/settings/account-linking")
    );
    const showCalendarWhiteIcon = computed(() => 
      route.path.startsWith("/patient/appointment")
    );
    const showSettingWhiteIcon = computed(() =>
      route.path.startsWith("/patient/settings") &&
      route.path !== "/patient/settings/account-linking") ||
      route.path.startsWith("/admin/settings") ||
      route.path.startsWith("/admin/vital-details");

    const showAdminWhiteIcon = computed(() => 
      route.path.startsWith("/admin/report-and-statictics")
    );
    const isPatientSettings = computed(() => 
      route.path.startsWith("/patient")
    );
    const isAdminSettings = computed(() => 
      route.path.startsWith("/admin")
    );
</script>