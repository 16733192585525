import axios from "@/api";

const state = {
  isLoading: false,
  allNotifications: [],
  alerts: [],
  announcements: [],
  reminders: [],
  page: {
    all: 1,
    alert: 1,
    announcement: 1,
    reminder: 1,
  },
  perPage: 10,
  total: {
    all: 0,
    alert: 0,
    announcement: 0,
    reminder: 0,
  },
};

const flattenNotifications = (notifications) => {
  return notifications.flatMap((notification) => {
    if (Array.isArray(notification.notifications)) {
      return [notification, ...flattenNotifications(notification.notifications)];
    }
    return [notification];
  });
};

const getters = {
  getAllNotifications: (state) => state.allNotifications,
  getAlerts: (state) => state.alerts,
  getAnnouncements: (state) => state.announcements,
  getReminders: (state) => state.reminders,
  getNotificationCounts: (state) => ({
    allNotifications: state.total.all,
    alerts: state.total.alert,
    announcements: state.total.announcement,
    reminders: state.total.reminder,
  }),
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setNotifications(state, { type, notifications, total, page }) {
    const key = type === null ? "all" : type;

    state.total[key] = total;
    state.page[key] = page;

    const flattenedNotifications = flattenNotifications(notifications);

    const targetKey = key === "all" ? "allNotifications" : `${key}s`;
    if (page === 1) {
      state[targetKey] = flattenedNotifications;
    } else {
      const existingIds = new Set(state[targetKey].map((n) => n.id));
      const filteredNewNotifications = flattenedNotifications.filter((n) => !existingIds.has(n.id));
      state[targetKey].push(...filteredNewNotifications);
    }
  },
  addNotification(state, notification) {
    state.allNotifications.unshift(notification);
    switch (notification.notification_type) {
      case "Alert":
        state.alerts.unshift(notification);
        break;
      case "Announcement":
        state.announcements.unshift(notification);
        break;
      case "Reminder":
        state.reminders.unshift(notification);
        break;
    }
  },

  incrementPage(state, type) {
    const pageKey = type === null ? "all" : type;
    state.page[pageKey]++;
  },
  removeNotification(state, notification_id) {
    const notification = state.allNotifications.find((n) => n.id === notification_id);

    const updateArray = (key) => {
      state[key] = state[key].filter((n) => n.id !== notification_id);
    };

    updateArray("allNotifications");
    updateArray("alerts");
    updateArray("announcements");
    updateArray("reminders");

    if (notification) {
      const type = notification.notification_type.toLowerCase();
      if (state.total[type] > 0) {
        state.total[type]--;
      }
    }
  },  
  setNotificationCounts(state, { type, adjustment }) {
    const key = type === "all" ? "all" : type;
    state.total[key] = Math.max(0, state.total[key] + adjustment);
  },
};

const actions = {
  async fetchNotifications({ commit, state }, { type_filter, page = 1 }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`/data/patient/notifications`, {
        params: { type_filter, page, per_page: state.perPage },
      });
  
      commit("setNotifications", {
        type: type_filter,
        notifications: response.data.notifications,
        total: response.data.total,
        page,
      });
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error("Rate limit exceeded. Please try again later.");
      } else {
        console.error(`Error fetching notifications for type: ${type_filter}`, error);
      }
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAllNotifications({ dispatch, rootGetters }) {
    const isAdmin = rootGetters["auth/getIsAdmin"];

    if (!isAdmin) {
      await Promise.all([
        dispatch("fetchNotifications", { type_filter: null }),
        dispatch("fetchNotifications", { type_filter: "alert" }),
        dispatch("fetchNotifications", { type_filter: "announcement" }),
        dispatch("fetchNotifications", { type_filter: "reminder" }),
      ]);
    }
  },
  async deleteNotification({ commit, dispatch }, notification_id) {
    try {
      const response = await axios.delete(`/data/patient/notification/${notification_id}`);
      if (response.status === 200) {
        commit("removeNotification", notification_id);
        commit("setNotificationCounts", { type: "all", adjustment: -1 });
        
        await dispatch("fetchNotifications", { type_filter: null });
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  },  
  

  //checks if there are more items to load before incrementing the page and fetching more notifications
  async loadMoreNotifications({ commit, state, dispatch }, { type_filter }) {
    const notificationsKey = type_filter === null ? "allNotifications" : `${type_filter}s`;
    const currentPage = state.page[type_filter];
    const totalItems = state.total[type_filter];
    const loadedItems = (state[notificationsKey] || []).length;

    if (loadedItems >= totalItems) {
      return;
    }

    try {
      commit("incrementPage", type_filter);
      await dispatch("fetchNotifications", {
        type_filter,
        page: state.page[type_filter],
      });
    } catch (error) {
      console.error("Failed to load more notifications:", error);
    }
  },
  //getNotificationsPreferences
  async getNotificationsPreferences() {
    const response = await axios.get(`/data/patient/notifications/preferences`);

    return response.data.preferences;

  },

  //update notifications setting
  async configureNotificationsForPatient({ commit }, payload) {
    const response = await axios.put(
      `/data/patient/notifications/preferences`,
      payload
    );
    return response.data; 
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
