import axios from "@/api";
import { root } from "postcss";

const state = {};

const getters = {};

const actions = {
  async requestForEmailOtp() {
    try {
      const response = await axios.put(`/auth/email-otp`);
      return response.data;
    } catch (error) {
      console.error("requestForEmailOtp error", error);
      throw error;
    }
  },

  async enable2faViaEmailOtp(_, payload) {
    try {
      const response = await axios.post(`/auth/email-otp`, {
        email_otp: payload.email_otp,
      });
      return response.data;
    } catch (error) {
      console.error("enable2faViaEmailOtp error", error);
      throw error;
    }
  },

  async disable2faViaEmailOtp(_, payload) {
    try {
      const response = await axios.delete(`/auth/email-otp`, {
        email_otp: payload.email_otp,
        recovery_code: payload.recovery_code,
      });
      return response.data;
    } catch (error) {
      console.error("disable2faViaEmailOtp error", error);
      throw error;
    }
  },

  async loggedOutPatientRequestForEmailOtp(_, email) {
    try {
      const response = await axios.get(`auth/email-otp?email=${email}`);
      return response.data;
    } catch (error) {
      console.error("loggedOutPatientRequestForEmailOtp err axi", error);
      throw error;
    }
  },

  async loginWithEmailOtp({ commit }, payload) {
    try {
      const response = await axios.post(`/auth/login`, {
        email: payload.email,
        password: payload.password,
        email_otp: payload.email_otp,
      });
      const { data } = response;
      commit("auth/setLoginStatus", data[0].login, { root: true });
      commit("user/setUser", data[0].user, { root: true });
      commit("user/setPatientId", data[0].patient_id, { root: true });
      if (data[0].user.roles.includes("admin")) {
        commit("auth/setAdminStatus", true, { root: true });
      }
      return response.data;
    } catch (error) {
      console.error("login response with otp err in axi", error);
      throw error;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
