<template>
  <section
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="space-y-6 bg-white w-full xl:mx-10 rounded-lg shadow-xl p-4 md:space-y-8 md:p-10">
      <div
        class="w-full flex flex-col gap-4 items-start md:gap-6"
      >
        <h1 class="text-DarkJungleGreen text-xl font-bold md:text-2xl">
          Notifications Settings
        </h1>

        <NotificationSetting />
        <!-- Notifications Tab Component -->
        <!-- <NotificationsSettingTab
          :tabs="tabs"
          :initialTab="activeTab"
          @tabChange="handleTabChange"
        /> -->
      </div>

      <!-- Dynamic Content Rendering -->
      <!-- <section>
       
        <IntegrationsSetting v-else-if="activeTab === 'integrations'" />
        <PasswordAndSecurity v-else-if="activeTab === 'password'" />
      </section> -->
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import NotificationsSettingTab from "@/components/main/patient/notificationsSettings/NotificationsSettingTab.vue";
import NotificationSetting from "@/components/main/patient/notificationsSettings/NotificationSetting.vue";
import IntegrationsSetting from "@/components/main/patient/notificationsSettings/IntegrationsSetting.vue";
import PasswordAndSecurity from "@/components/main/patient/notificationsSettings/PasswordAndSecurity.vue";

const tabs = [
  { name: "notifications", label: "Notifications" },
  { name: "integrations", label: "Integrations" },
  { name: "password", label: "Password & Security" },
];

const activeTab = ref("notifications");

const handleTabChange = (tabName) => {
  activeTab.value = tabName;
};
</script>

<style scoped></style>
